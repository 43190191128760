import React, { useEffect } from "react"
import { useStaticQuery, graphql, withPrefix } from "gatsby"

import PrivateRoute from "../../functions/privateRoute"
import { Router, Route } from "@reach/router"
import ResetPassword from "./ResetPassword"
import Layout from "../../components/layout"

const Account = (props) => {

    return (
        <Layout>
            <Router basepath={"/reset-password"}>
                <ResetPassword path={"/:email/:resetkey"} />
            </Router>
        </Layout>
    )
}

export default Account;
